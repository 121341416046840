import React, { ReactNode, useEffect } from "react";

import { useHistory } from "react-router-dom";

interface Props {
	children: ReactNode;
}

export const AppNavigationProvider: React.FC<Props> = (props) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const history = useHistory<any>();
	const { children } = props;

	useEffect(() => {
		const navigationEventController = (e: CustomEvent) => {
			const { detail = {} } = e;
			switch (detail.type) {
				case "push": {
					history.push(detail.path, detail.state);
					break;
				}
				case "replace": {
					history.replace(detail.path, detail.state);
					break;
				}
				case "goBack": {
					history.go(-1);
					break;
				}
				case "updateLocalesHistoryState": {
					const { translations } = detail.state || {};
					if (!translations) return;
					const urlTranslations: { [key: string]: string } = {};
					// eslint-disable-next-line no-restricted-syntax
					for (const translation of translations) {
						urlTranslations[translation.locale] = `${
							translation.locale === "vi" ? "" : `/${translation.locale}`
						}${translation.slug[0] === "/" ? "" : "/"}${translation.slug}`;
					}
					history.location.state = {
						...history.location.state,
						urlTranslations,
					};
					break;
				}
				case "changeLanguage": {
					const { urlTranslations } = history.location?.state || {};
					const { lang } = detail.state || {};
					if (urlTranslations && lang) {
						window.location.href =
							urlTranslations[lang] + history.location?.search;
					}
					break;
				}
				default:
					break;
			}
		};

		window.addEventListener("route", navigationEventController);

		return () => {
			window.removeEventListener("route", navigationEventController);
		};
	}, [history]);

	return <>{children}</>;
};
