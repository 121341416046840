import { CSSProperties, useEffect, useRef, useState } from "react";

import ReactPlayer from "react-player";

import { Heading } from "components/atoms/Heading";
import { Icon } from "components/atoms/Icon";
import { Container } from "components/organisms/Grid";
import { mapModifiers } from "helpers/component";

type Modifiers =
	| "homepage"
	| "footer"
	| "inline"
	| "inline-fluid"
	| "latest-news-detail";

export interface Props {
	modifiers?: Modifiers;
	title?: React.ReactNode;
	innerHtmlTitle?: string;
	titleStyle?: CSSProperties;
	description?: React.ReactNode;
	extraData?: React.ReactNode;
	imgPCSrc: string;
	imgTABSrc?: string;
	imgSPSrc?: string;
	source?: string;
	isRtl?: boolean;
	useParallaxScrolling?: boolean;
	useHeadingParagraph?: boolean;
	hasVideo?: boolean;
	onClickOpenModal?: () => void;
}

export const Banner: React.FC<Props> = ({
	modifiers,
	title,
	innerHtmlTitle,
	titleStyle,
	description,
	extraData,
	imgPCSrc,
	imgTABSrc,
	imgSPSrc,
	isRtl,
	useParallaxScrolling,
	source,
	useHeadingParagraph,
	hasVideo,
	onClickOpenModal,
}) => {
	const rootRef = useRef<HTMLImageElement | null>(null);
	const isUsingIphoneIpad = /iPad|iPhone/.test(navigator.userAgent);
	const [showConentOutside, setShowConentOutside] = useState<boolean>(false);

	useEffect(() => {
		const rootTarget = rootRef.current;

		if (!rootTarget) return undefined;

		const handleDetectResize = (mqle: MediaQueryList | MediaQueryListEvent) => {
			if (mqle.matches) {
				rootTarget.style.backgroundImage = `url(${imgTABSrc || imgPCSrc})`;
			} else {
				rootTarget.style.backgroundImage =
					window.innerWidth < 576 && imgSPSrc
						? `url(${imgSPSrc})`
						: `url(${imgPCSrc})`;
			}
		};

		const mql = window.matchMedia("(max-width: 1199px) and (min-width: 576px)");
		mql.addEventListener("change", handleDetectResize);
		handleDetectResize(mql);

		return () => {
			mql.removeEventListener("change", handleDetectResize);
		};
	}, [imgPCSrc, imgTABSrc, imgSPSrc]);

	useEffect(() => {
		const handleDetectResize = (mqle: MediaQueryList | MediaQueryListEvent) => {
			setShowConentOutside(mqle.matches);
		};

		const mql = window.matchMedia("(max-width: 999px)");
		mql.addEventListener("change", handleDetectResize);
		handleDetectResize(mql);

		return () => {
			mql.removeEventListener("change", handleDetectResize);
		};
	}, []);

	return (
		<div
			className={mapModifiers(
				"o-banner",
				modifiers,
				isRtl && "righttoleft",
				source && "useplayer",
				useParallaxScrolling && "useParallaxScrolling",
				isUsingIphoneIpad && "useIPhoneIPad",
				hasVideo && "hasVideo"
			)}
		>
			<div className="o-banner_inner">
				{!source && (
					<div className="o-banner_imagewrapper">
						<div className="o-banner_image" ref={rootRef} />
					</div>
				)}
				{source && (
					<>
						{!hasVideo ? (
							<div className="o-banner_player">
								<ReactPlayer
									playing
									playsinline
									loop
									muted
									className="o-banner_reactplayer"
									url={source}
									width="100%"
									height="100%"
								/>
							</div>
						) : (
							<div className="o-banner_imagewrapper">
								<div className="o-banner_image" ref={rootRef}>
									<Icon
										clickable
										iconName="video-play-icon"
										onClick={onClickOpenModal}
									/>
								</div>
							</div>
						)}
					</>
				)}
				{!showConentOutside && (
					<BannerContent
						useHeadingParagraph={useHeadingParagraph}
						title={title}
						innerHtmlTitle={innerHtmlTitle}
						titleStyle={titleStyle}
						description={description}
						extraData={extraData}
						modifiers="inner"
					/>
				)}
			</div>
			{showConentOutside && (
				<BannerContent
					useHeadingParagraph={useHeadingParagraph}
					title={title}
					innerHtmlTitle={innerHtmlTitle}
					titleStyle={titleStyle}
					description={description}
					extraData={extraData}
					modifiers="outer"
				/>
			)}
		</div>
	);
};

interface BannerContentProps
	extends Pick<
		Props,
		| "useHeadingParagraph"
		| "title"
		| "innerHtmlTitle"
		| "titleStyle"
		| "description"
		| "extraData"
	> {
	modifiers?: "inner" | "outer";
}

const BannerContent: React.FC<BannerContentProps> = ({
	useHeadingParagraph,
	title,
	innerHtmlTitle,
	titleStyle,
	description,
	extraData,
	modifiers,
}) => {
	return (
		<Container className={mapModifiers("o-banner_container", modifiers)}>
			<div className={mapModifiers("o-banner_content", modifiers)}>
				{(title || innerHtmlTitle) && (
					<div className="o-banner_title">
						<Heading
							type="h1"
							fontStyle="italic"
							innerHtmlChildren={innerHtmlTitle}
							useParagraph={useHeadingParagraph}
							style={titleStyle}
						>
							{title}
						</Heading>
					</div>
				)}
				{description && (
					<div className="o-banner_description">{description}</div>
				)}
				{extraData && <div className="o-banner_extradata">{extraData}</div>}
			</div>
		</Container>
	);
};
