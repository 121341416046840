import { apiSystems301Redirect } from "services/System";

import { replace } from "./navigation";

const checkRedirect = async (
	handleSuccess?: () => unknown,
	handleError?: () => unknown
) => {
	try {
		const redirectRes = await apiSystems301Redirect(window.location.pathname);
		const { active, to } = redirectRes.data?.data || {};

		if (active) {
			// eslint-disable-next-line no-unused-expressions
			to.includes("http://") || to.includes("https://")
				? window.location.replace(to)
				: replace(to, { redirect301: true });
			if (handleSuccess) {
				handleSuccess();
			}
		}
	} catch {
		if (handleError) handleError();
	}
};

export default checkRedirect;
