import { ITranslation } from "services/Page/types";

const createRouteChangeEvent = <T>(routeEventProps: RouteCustomEvent<T>) => {
	const { path, state, type } = routeEventProps;
	return new CustomEvent("route", {
		detail: {
			type,
			path,
			state,
		},
	});
};

export const push = <T>(to: string, state?: T) => {
	if (!window) return;
	const event = createRouteChangeEvent({ path: to, state, type: "push" });
	window.dispatchEvent(event);
};

export const replace = <T>(to: string, state?: T) => {
	if (!window) return;
	const event = createRouteChangeEvent({ path: to, state, type: "replace" });
	window.dispatchEvent(event);
};

export const goBack = () => {
	if (!window) return;
	const event = createRouteChangeEvent({ type: "goBack" });
	window.dispatchEvent(event);
};

export const updateLocalesHistoryState = (
	translations: Array<ITranslation>
) => {
	if (!window) return;
	const event = createRouteChangeEvent({
		type: "updateLocalesHistoryState",
		state: {
			translations,
		},
	});
	window.dispatchEvent(event);
};

export const changeLanguage = (lang: string) => {
	if (!window) return;
	const event = createRouteChangeEvent({
		type: "changeLanguage",
		state: {
			lang,
		},
	});
	window.dispatchEvent(event);
};
