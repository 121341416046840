import React from "react";

import { Button } from "components/atoms/Button";
import { Heading } from "components/atoms/Heading";

export interface Props {
	title: string;
	description: string;
	buttonTitle: string;
	href: string;
	target?: string;
}

export const InfoBlock: React.FC<Props> = ({
	title,
	description,
	buttonTitle,
	href,
	target,
}) => {
	return (
		<div className="m-infoblock">
			<Heading fontWeight="medium">{title}</Heading>
			<div
				className="m-infoblock_description"
				dangerouslySetInnerHTML={{ __html: description }}
			/>
			<Button iconName="arsenic-right-circle-arrow" href={href} target={target}>
				{buttonTitle}
			</Button>
		</div>
	);
};
