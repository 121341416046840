import http from "services/http";

import {
	INews,
	NewsPayload,
	INewsDetail,
	NewsTagPayload,
	INewsTag,
} from "./type";

export const apiGetNews = ({
	type,
	page,
	limit,
	keyword,
	onlyB3,
}: NewsPayload) =>
	http.get<BaseAPIResponse<Array<INews>>>("/news", {
		params: {
			type,
			page,
			limit,
			keyword,
			only_b3: onlyB3,
		},
	});

export const apiGetNewsBySlug = (slug: string) =>
	http.get<BaseAPIResponse<INewsDetail>>(`/news/${slug}`, {
		shouldCatchAPIsError: true,
		shouldCheckRedirectTo: true,
		isPreview: true,
	});

export const apiGetNewsTags = (params?: NewsTagPayload) =>
	http.get<BaseAPIResponse<Array<INewsTag>>>("/news-tags/get-list", { params });
