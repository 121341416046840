import { useMemo } from "react";

import { InfoBlock } from "components/molecules/InfoBlock";
import { Banner } from "components/organisms/Banner";
import { Section } from "components/organisms/Section";
import { getLinkThroughLocale } from "helpers/utils";
import { downloadStaticFile } from "services/File";
import { IFooterBanner } from "services/Page/types";

interface Props {
	footerBanners?: Array<IFooterBanner>;
}

const IndexSection: React.FC<Props> = ({ footerBanners }) => {
	const renderInforBlock = useMemo(
		() => (
			<InfoBlock
				title={footerBanners?.[0]?.data?.title || ""}
				description={footerBanners?.[0]?.data?.description || ""}
				buttonTitle={footerBanners?.[0]?.data?.cta?.text || ""}
				href={getLinkThroughLocale(footerBanners?.[0]?.data?.cta?.url)}
				target={footerBanners?.[0]?.data?.cta?.target}
			/>
		),
		[footerBanners]
	);

	return footerBanners && footerBanners.length > 0 ? (
		<Section useDiv fluid modifiers="no-horizontal-spacing">
			<Banner
				extraData={<div style={{ maxWidth: 386 }}>{renderInforBlock}</div>}
				imgPCSrc={downloadStaticFile(footerBanners?.[0]?.data?.imageDesktop)}
				imgTABSrc={
					footerBanners?.[0]?.data?.imageTablet
						? downloadStaticFile(footerBanners[0].data.imageTablet)
						: undefined
				}
				imgSPSrc={
					footerBanners?.[0]?.data?.imageMobile
						? downloadStaticFile(footerBanners[0].data.imageMobile)
						: undefined
				}
				isRtl
				modifiers="footer"
			/>
		</Section>
	) : null;
};

export default IndexSection;
