import React from "react";

import { Icon, IconName } from "components/atoms/Icon";
import { Link } from "components/utils/Link";
import { mapModifiers } from "helpers/component";

type ButtonType = "outline";
type BackgroundColorModifier = "french-plum";

export interface Props {
	backgroundColorModifier?: BackgroundColorModifier;
	buttonType?: ButtonType;
	iconName: IconName;
	type?: "submit" | "button" | "reset";
	href?: string;
	target?: string;
	disabled?: boolean;
	fullWidth?: boolean;
	onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
}

export const Button: React.FC<Props> = ({
	backgroundColorModifier,
	buttonType,
	disabled,
	fullWidth,
	children,
	href,
	target,
	type,
	iconName,
	onClick,
}) => {
	return href ? (
		<Link
			className={mapModifiers(
				"a-button",
				fullWidth && "fullWidth",
				disabled && "disabled",
				buttonType,
				backgroundColorModifier && `background-color-${backgroundColorModifier}`
			)}
			to={href}
			target={target}
			onClick={onClick}
		>
			{children}
			<div className="a-button_iconwrapper">
				<Icon iconName={iconName} />
			</div>
		</Link>
	) : (
		<button
			// eslint-disable-next-line react/button-has-type
			type={type}
			className={mapModifiers(
				"a-button",
				fullWidth && "fullWidth",
				disabled && "disabled",
				buttonType,
				backgroundColorModifier && `background-color-${backgroundColorModifier}`
			)}
			disabled={disabled}
			onClick={onClick}
		>
			{children}
			<div className="a-button_iconwrapper">
				<Icon iconName={iconName} />
			</div>
		</button>
	);
};

Button.defaultProps = {
	type: "button",
};
