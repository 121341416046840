import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { AppNavigationProvider } from "contexts/Navigation";
import { AppInitializer } from "lib/appinitializer";
import { GoogleAnalytics } from "lib/googleAnalytics";
import { GoogleTagManager } from "lib/googleTagManager";
import Routes from "pages/routes";

import store, { persistor } from "./stores";

import "index.scss";

const App = () => (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Router>
				<AppNavigationProvider>
					<AppInitializer />
					<GoogleAnalytics />
					<GoogleTagManager />
					<Routes />
				</AppNavigationProvider>
			</Router>
		</PersistGate>
	</Provider>
);
export default App;
