import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import fourZeroThree from "./403.json";
import fiveZeroZero from "./500.json";
import fiveZeroThree from "./503.json";
import system from "./system.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: {
		403: fourZeroThree.en,
		500: fiveZeroZero.en,
		503: fiveZeroThree.en,
		system: system.en,
	},
	vi: {
		403: fourZeroThree.vi,
		500: fiveZeroZero.vi,
		503: fiveZeroThree.vi,
		system: system.vi,
	},
};

const i18nInstance = i18n.createInstance();

export const SupportedLngs = ["vi", "en"] as const;

export const initializedI18next = (params?: { cb?: () => void }) => {
	const { cb } = params || {};
	i18nInstance
		.use(HttpBackend)
		.use(detector)
		.use(initReactI18next) // passes i18n down to react-i18next
		.init(
			{
				// NOTE: i18n.lng property should not be set to when using language detector
				// @see https://stackoverflow.com/questions/54514834/i18next-browser-languagedetector-path-not-working
				// NOTE: The `HttpBackend` package loads resources through loadPath. So, we don't need to set up resource initializer
				// resources,
				supportedLngs: SupportedLngs,
				fallbackLng: false,
				keySeparator: ".",
				interpolation: {
					escapeValue: false, // react already safes from xss
				},
				react: {
					bindI18n: "languageChanged",
					bindI18nStore: "added removed",
					nsMode: "default",
				},
				detection: {
					// for i18next-browser-languagedetector
					// Use `htmlTag` to support URLs without language prefix
					order: ["path", "htmlTag"],
					lookupFromPathIndex: 0,
					htmlTag: document.documentElement,
				},
				backend: {
					loadPath: `${process.env.REACT_APP_API_BASE_URL}/spa-translations?locale={{lng}}`,
				},
			},
			cb
		);

	// eslint-disable-next-line no-restricted-syntax
	for (const lang of Object.keys(resources)) {
		i18nInstance.addResourceBundle(
			lang,
			"local",
			resources[lang as keyof typeof resources]
		);
	}
};

export default i18nInstance;
