import { createSlice } from "@reduxjs/toolkit";

import { IMenu } from "services/Menu/types";

import {
	footerMenusAction,
	footerQuickLinksAction,
	headerMenusAction,
} from "./thunks";

export interface GroupMenu extends IMenu {
	href: string;
	children?: GroupMenu[];
}

export interface MenuReducerState {
	header?: Array<GroupMenu>;
	footer?: Array<IMenu>;
	quickLinks?: Array<IMenu>;
}

const initialState: MenuReducerState = {};

const MenusSlice = createSlice({
	name: "menus",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(footerMenusAction.fulfilled, (state, action) => {
			state.footer = action.payload.footer;
		});
		builder.addCase(footerQuickLinksAction.fulfilled, (state, action) => {
			state.quickLinks = action.payload.quickLinks;
		});
		builder.addCase(headerMenusAction.fulfilled, (state, action) => {
			state.header = action.payload.header;
		});
	},
});

export default MenusSlice;
