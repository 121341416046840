import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiSystemsGeneral } from "services/System";

export const systemsGeneralAction = createAsyncThunk(
	"system/systemsGeneralAction",
	async () => {
		const res = await apiSystemsGeneral();
		return { general: res.data.data };
	}
);
