import { useEffect } from "react";

import TagManager from "react-gtm-module";

import { useAppSelector } from "stores";

export const GoogleTagManager = () => {
	const { general } = useAppSelector((state) => state.system);

	useEffect(() => {
		if (!general?.googleTag) return;
		TagManager.initialize({
			gtmId: general.googleTag,
		});
	}, [general?.googleTag]);

	return null;
};
