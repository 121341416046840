import React, { useMemo } from "react";

import dayjs from "dayjs";

import { Button } from "components/atoms/Button";
import { Heading } from "components/atoms/Heading";
import { CaptionBlock } from "components/molecules/CaptionBlock";
import { JoinOurTeam } from "components/molecules/JoinOurTeam";
import { LatestNewsSlider } from "components/molecules/LatestNewsSlider";
import { OurValuesCard } from "components/molecules/OurValuesCard";
import { General } from "components/organisms/General";
import { Col, Row } from "components/organisms/Grid";
import { Section } from "components/organisms/Section";
import { Seo } from "helpers/seo";
import {
	getLinkThroughLocale,
	getLinkThroughLocaleAndPathPrefixCode,
	getOgByLanguage,
} from "helpers/utils";
import { useAsyncAction } from "hooks/useAsyncAction";
import useDidMount from "hooks/useDidMount";
import FooterBannerSection from "pages/Common/FooterBanner";
import MainBannerSection from "pages/Common/MainBanner";
import { downloadStaticFile } from "services/File";
import { apiGetNews } from "services/News";
import { IPageHome } from "services/Page/types";
import { useAppSelector } from "stores";

interface HomePageNewsSectionProps {
	heading: string;
	viewAllTitle: string;
	mediaCentreSlug: string;
}

const HomePageNewsSection: React.FC<HomePageNewsSectionProps> = ({
	heading,
	viewAllTitle,
	mediaCentreSlug,
}) => {
	const [getNewsExec, getNewsState] = useAsyncAction(apiGetNews);

	const latestNews = useMemo(() => {
		return (
			getNewsState.data?.data?.data?.map(
				({
					id,
					thumbnail,
					title,
					publishedAt,
					description,
					slug,
					type,
					externalLink,
					newTab,
				}) => ({
					id,
					imgSrc: downloadStaticFile(thumbnail),
					imgAlt: title,
					date: dayjs(publishedAt).format("DD/MM/YYYY"),
					description,
					target: newTab ? "_blank" : "_self",
					href:
						type === 2
							? getLinkThroughLocale(externalLink)
							: getLinkThroughLocaleAndPathPrefixCode({
									linkUrl: `/${slug}`,
									prefixCode: "LATESTNEWPREFIX",
							  }),
				})
			) || []
		);
	}, [getNewsState.data?.data.data]);

	useDidMount(() => {
		getNewsExec({ page: 1, limit: 9, type: "1" });
	});

	return latestNews.length > 0 ? (
		<Section>
			<div
				className="d-flex justify-content-between align-items-center mb-4 u-pb-14"
				style={{ borderBottom: "1px solid #E0E0E0" }}
			>
				<Heading fontWeight="medium">
					<span style={{ color: "#3C4242" }}>{heading}</span>
				</Heading>
				<div style={{ flex: "0 0 auto", marginLeft: 20 }}>
					<Button
						buttonType="outline"
						iconName="french-plum-right-circle-arrow"
						href={mediaCentreSlug}
					>
						<span style={{ color: "#830051" }}>{viewAllTitle}</span>
					</Button>
				</div>
			</div>
			<LatestNewsSlider contents={latestNews} />
		</Section>
	) : null;
};

interface Props {
	pageState: IPageHome;
}

const IndexPage: React.FC<Props> = ({ pageState }) => {
	const { staticSlug } = useAppSelector((state) => state.page);

	const {
		seoData,
		blocksData: { banner, media, session1, session2 },
		footerBanners,
	} = pageState;

	return (
		<General
			helmet={
				<Seo
					seoData={seoData}
					ogData={getOgByLanguage(pageState.pageData?.translations)}
				/>
			}
			modifiers={footerBanners.length > 0 ? undefined : "marginbottom"}
		>
			<MainBannerSection
				isHomePage
				title={banner?.title}
				description={banner?.description}
				imgPCSrc={banner?.banner || ""}
				imgTABSrc={banner?.bannerTablet}
				imgSPSrc={banner?.bannerMobile}
				source={banner?.bannerVideo}
				hasVideo={!!banner?.bannerVideo}
			/>

			<Section useDiv>
				<Row>
					<Col lg="8">
						<CaptionBlock
							lineColor="#830051"
							type="primary"
							imgSrc={downloadStaticFile(session1?.imageLeft || "")}
							caption={
								<div
									dangerouslySetInnerHTML={{
										__html: session1?.titleLeft || "",
									}}
								/>
							}
							href={getLinkThroughLocale(session1?.urlLearnMore)}
							hasLearnMore={!!session1?.urlLearnMore}
							learnMoreTitle="Learn more"
						/>
					</Col>

					<Col
						lg="4"
						className="d-flex flex-column justify-content-between u-mt-20 u-mt-lg-0"
					>
						{session1?.list.map(
							({ banner: captionBlockBanner, title, url }, index) => (
								// eslint-disable-next-line react/no-array-index-key
								<div key={index} className="u-mt-20 u-mt-lg-0">
									<CaptionBlock
										lineColor={index === 0 ? "#C3D217" : "#D00271"}
										type="secondary"
										imgSrc={downloadStaticFile(captionBlockBanner)}
										caption={title || ""}
										href={getLinkThroughLocale(url)}
									/>
								</div>
							)
						)}
					</Col>
				</Row>
			</Section>

			<Section useDiv>
				<Row>
					<Col lg="8">
						<JoinOurTeam
							imgSrc={downloadStaticFile(session2?.blockLeft?.banner || "")}
							title={session2?.blockLeft?.title || ""}
							description={
								<div
									dangerouslySetInnerHTML={{
										__html: session2?.blockLeft?.description || "",
									}}
								/>
							}
							buttonTitle={session2?.blockLeft?.learnMore || ""}
							href={getLinkThroughLocale(session2?.blockLeft?.urlLearnMore)}
						/>
					</Col>

					<Col lg="4" className="u-mt-20 u-mt-lg-0">
						<OurValuesCard
							imgSrc={downloadStaticFile(session2?.blockRight?.banner || "")}
							title={session2?.blockRight?.title || ""}
							titleSize="largest"
							titleFontWeight="medium"
							description={session2?.blockRight?.description}
							subTitle={session2?.blockRight?.learnMore}
							subHref={
								session2?.blockRight?.urlLearnMore
									? getLinkThroughLocale(session2.blockRight.urlLearnMore)
									: undefined
							}
							lineColor="#183861"
						/>
					</Col>
				</Row>
			</Section>

			<HomePageNewsSection
				heading={media?.title || ""}
				mediaCentreSlug={getLinkThroughLocale(
					`/${staticSlug?.MEDIA_CENTRE_E1}`
				)}
				viewAllTitle={media?.viewAll || ""}
			/>

			<FooterBannerSection footerBanners={footerBanners} />
		</General>
	);
};

export default IndexPage;
