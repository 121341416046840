/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { push } from "helpers/navigation";
import checkRedirect from "helpers/redirect";
import { getLinkThroughLocaleAndPathPrefixCode } from "helpers/utils";
import i18n from "translation";

declare module "axios" {
	export interface AxiosInstance {
		request<T = any, R = AxiosResponse<T>>(
			config: AxiosRequestConfigCustom
		): Promise<R>;
		get<T = any, R = AxiosResponse<T>>(
			url: string,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
		delete<T = any, R = AxiosResponse<T>>(
			url: string,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
		head<T = any, R = AxiosResponse<T>>(
			url: string,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
		options<T = any, R = AxiosResponse<T>>(
			url: string,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
		post<T = any, R = AxiosResponse<T>>(
			url: string,
			data?: any,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
		put<T = any, R = AxiosResponse<T>>(
			url: string,
			data?: any,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
		patch<T = any, R = AxiosResponse<T>>(
			url: string,
			data?: any,
			config?: AxiosRequestConfigCustom
		): Promise<R>;
	}
}

const http = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
});

type AxiosRequestConfigCustom = AxiosRequestConfig & {
	shouldCatchAPIsError?: boolean;
	shouldCheckRedirectTo?: boolean;
	shouldNOTUseLocale?: boolean;
	isPreview?: boolean;
};

http.interceptors.request.use(
	(config: AxiosRequestConfigCustom) => {
		const locale = i18n.language;
		if (!config.shouldNOTUseLocale && locale && locale !== "en") {
			config.params = {
				...(config.params || {}),
				locale,
			};
		}
		if (config.isPreview) {
			const searchParams = new URLSearchParams(window.location.search);
			const previewToken = searchParams.get("preview");
			if (previewToken) {
				config.url = `/preview/${previewToken}`;
			}
		}
		return config;
	},
	(error: AxiosError<any>) => {
		return Promise.reject(error);
	}
);

http.interceptors.response.use(
	(response: AxiosResponse<any>) => {
		return response;
	},
	async (error: AxiosError<any> & { config: AxiosRequestConfigCustom }) => {
		if (error?.config?.shouldCatchAPIsError) {
			switch (error.response?.status) {
				case 403: {
					push(
						getLinkThroughLocaleAndPathPrefixCode({
							linkUrl: "",
							prefixCode: 403,
						})
					);
					break;
				}
				case 500: {
					push(
						getLinkThroughLocaleAndPathPrefixCode({
							linkUrl: "",
							prefixCode: 500,
						})
					);
					break;
				}
				case 503: {
					push(
						getLinkThroughLocaleAndPathPrefixCode({
							linkUrl: "",
							prefixCode: 503,
						})
					);
					break;
				}
				default:
					break;
			}
		}

		if (error.response?.status === 404 && error.config.shouldCheckRedirectTo) {
			await checkRedirect(
				() => {
					if (error.response && error.response.status) {
						error.response.status = 301;
					}
					return Promise.reject(error);
				},
				() => {
					return Promise.reject(error);
				}
			);
		}

		return Promise.reject(error);
	}
);

export default http;
