import React from "react";

import { Button } from "components/atoms/Button";
import { Heading } from "components/atoms/Heading";
import { Image } from "components/atoms/Image";
import { Text } from "components/atoms/Text";

export interface Props {
	imgSrc: string;
	title: string;
	description: React.ReactNode;
	buttonTitle: string;
	href: string;
}

export const JoinOurTeam: React.FC<Props> = ({
	imgSrc,
	title,
	description,
	buttonTitle,
	href,
}) => {
	return (
		<div className="m-joinourteam">
			<Image alt={title} aspectRatio="1x1" src={imgSrc} />
			<div className="m-joinourteam_content">
				<div className="m-joinourteam_title">
					<Heading useParagraph fontWeight="medium">
						{title}
					</Heading>
				</div>
				<div>
					<Text>{description}</Text>
					<Button
						buttonType="outline"
						iconName="white-right-circle-arrow"
						href={href}
					>
						{buttonTitle}
					</Button>
				</div>
			</div>
		</div>
	);
};
