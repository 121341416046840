import React from "react";

import { mapModifiers } from "helpers/component";

type Modifier = "marginbottom";

export interface Props {
	modifiers?: Modifier | Array<Modifier>;
	backgroundColor?: string;
	children?: React.ReactNode;
	helmet?: React.ReactNode;
}

export const General: React.FC<Props> = ({
	modifiers,
	backgroundColor,
	helmet,
	children,
}) => {
	return (
		<div
			className={mapModifiers("o-general", modifiers)}
			style={{ backgroundColor }}
		>
			{helmet}
			{children}
		</div>
	);
};
