/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import parse from "html-react-parser";

const createScript = (jsx: JSX.Element, classScript?: string) => {
	const script = document.createElement("script");
	const node = document.createTextNode(
		jsx?.props?.dangerouslySetInnerHTML?.__html || ""
	);
	script.type = jsx?.props?.type || "";
	script.append(node);
	if (classScript) {
		script.className = classScript;
	}
	if (jsx?.props?.src) {
		script.src = jsx.props.src;
	}
	return script;
};

const initScriptSystems = (script?: string) => {
	if (!script) return;

	const scriptParse = parse(script);

	if (
		scriptParse &&
		!Array.isArray(scriptParse) &&
		typeof scriptParse === "object"
	) {
		const elements = createScript(scriptParse as JSX.Element);
		setTimeout(() => {
			window.document.head.append(elements);
		}, 3000);
	}

	if (Array.isArray(scriptParse)) {
		const elements: Array<HTMLScriptElement> = [];
		for (const item of scriptParse) {
			if (typeof item === "object") {
				elements.push(createScript(item));
			}
		}
		setTimeout(() => {
			window.document.head.append(...elements);
		}, 3000);
	}
};

export default initScriptSystems;
