import PATHS from "constants/paths";
import { ITranslation } from "services/Page/types";
import i18n from "translation";

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function convertSnakeToCamelCase(string: string) {
	const strSpltting = string.split("_");
	if (strSpltting.length === 1) return string;
	const camelStrs = strSpltting
		.slice(1)
		.map((str) => capitalizeFirstLetter(str.toLocaleLowerCase()));
	return strSpltting[0] + camelStrs.join("");
}

/**
 * Get correct windows pathname regardless of language
 * @Input Have locale - "/en/windows-pathname"
 * @Output Have locale - "/windows-pathname"
 * @Input Does NOT have locale - "/windows-pathname"
 * @Output Does NOT have locale - "/windows-pathname"
 */
export function getPathNameThroughLocale(fullPathName: string) {
	return i18n.language === "vi" ? fullPathName : fullPathName.slice(3) || "/"; // ["/"] for case "/locale"
}

/**
 * Get the correct path through the current language
 * NOTE: The linkUrl must begin with the `/` character
 */
export function getLinkThroughLocale(linkUrl?: string | null) {
	if (linkUrl === null || linkUrl === undefined) return "#";

	if (linkUrl.includes("http")) return linkUrl;

	const currentLocale = i18n.language;
	return `${currentLocale === "vi" ? "" : `/${currentLocale}`}${linkUrl}`;
}

/**
 * Get the correct path through the current language and path prefix code
 * NOTE: The linkUrl must begin with the `/` character
 */
export function getLinkThroughLocaleAndPathPrefixCode(params: {
	linkUrl?: string | null;
	prefixCode: keyof typeof PATHS;
}) {
	const { linkUrl, prefixCode } = params;
	if (linkUrl === null || linkUrl === undefined) return "#";

	if (linkUrl.includes("http")) return linkUrl;

	const currentLocale = i18n.language;
	return `${currentLocale === "vi" ? "" : `/${currentLocale}`}${
		PATHS[prefixCode][currentLocale as keyof typeof PATHS[keyof typeof PATHS]]
	}${linkUrl}`;
}

/**
 * Generate page translation data through the path prefix code
 */
export function genPageTranslationData(pathPrefixCode: keyof typeof PATHS) {
	const pathData = PATHS[pathPrefixCode];
	if (!pathData) return undefined;

	return Object.entries(pathData).map(([locale, slug]) => ({
		description: null,
		locale,
		slug: slug.substr(1), // Remove `/` character
		title: "",
	})) as Array<ITranslation>;
}

/**
 * Get og by language
 */

export function getOgByLanguage(
	translations?: {
		ogImage?: string;
		ogDescription?: string;
		ogTitle?: string;
		ogType?: string;
		locale?: string;
	}[]
) {
	const translate = translations?.find((x) => x.locale === i18n.language);
	return {
		ogTitle: translate?.ogTitle,
		ogImage: translate?.ogImage,
		ogDescription: translate?.ogDescription,
		ogType: translate?.ogType,
	};
}
