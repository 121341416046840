import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SystemsGeneral } from "services/System/types";

import { systemsGeneralAction } from "./thunks";

export interface SystemReducerState {
	general?: SystemsGeneral;
	isInitializedI18n: boolean;
	isOpenningLocaleModal: boolean;
	shouldRedirect302: boolean;
}

const initialState: SystemReducerState = {
	isInitializedI18n: false,
	isOpenningLocaleModal: false,
	shouldRedirect302: false,
};

const systemSlice = createSlice({
	name: "system",
	initialState,
	reducers: {
		onInitializedI18n: (state) => {
			state.isInitializedI18n = true;
		},
		toggleLocaleModal: (
			state,
			action: PayloadAction<{ shouldRedirect302: boolean } | undefined>
		) => {
			state.isOpenningLocaleModal = !state.isOpenningLocaleModal;
			state.shouldRedirect302 = action.payload
				? action.payload.shouldRedirect302
				: state.shouldRedirect302;
		},
		clearLocaleStateAction: (state) => {
			state.isOpenningLocaleModal = false;
			state.shouldRedirect302 = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(systemsGeneralAction.fulfilled, (state, action) => {
			state.general = action.payload.general;
		});
	},
});

export default systemSlice;
