import React, { useEffect } from "react";

import dayjs from "helpers/dayjs";
import initScriptSystems from "hooks/scriptSystems";
import useDerivedStateFromProps from "hooks/useDerivedStateFromProps";
import { useAppDispatch, useAppSelector } from "stores";
import {
	footerMenusAction,
	footerQuickLinksAction,
	headerMenusAction,
} from "stores/Menus/thunks";
import { pagesStaticSlugAction } from "stores/Page/thunks";
import systemSlice from "stores/System/reducer";
import { systemsGeneralAction } from "stores/System/thunks";
import i18n, { initializedI18next } from "translation";

/**
 * Do things that is required for the app iniatilly.
 * e.g. take token, fetch user info, etc.
 */
export const AppInitializer: React.FC = () => {
	const schemaOg = useAppSelector((state) => state.system.general?.schemaOrg);
	const dispatch = useAppDispatch();
	const { isOpenningLocaleModal, shouldRedirect302 } = useAppSelector(
		(store) => store.system
	);

	const onAppInitiaization = () => {
		dayjs.locale(i18n.language);
		dispatch(systemSlice.actions.onInitializedI18n());
		dispatch(systemsGeneralAction());
		dispatch(pagesStaticSlugAction());
		dispatch(footerMenusAction());
		dispatch(footerQuickLinksAction());
		dispatch(headerMenusAction());
		const scriptElm = document.createElement("script");
		scriptElm.src =
			i18n.language === "vi"
				? "//policy.cookiereports.com/e3f7b91b_panel-vi.js"
				: "//policy.cookiereports.com/e3f7b91b_panel-en-gb.js";
		window.document.body.appendChild(scriptElm);
	};

	useDerivedStateFromProps(() => {
		initializedI18next({
			cb: onAppInitiaization,
		});
	}, undefined);

	useEffect(() => {
		if (!isOpenningLocaleModal && shouldRedirect302) {
			onAppInitiaization();
			dispatch(systemSlice.actions.clearLocaleStateAction());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpenningLocaleModal, shouldRedirect302]);

	useEffect(() => {
		if (schemaOg) {
			initScriptSystems(schemaOg);
		}
	}, [schemaOg]);

	return null;
};
