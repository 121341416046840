export default {
	ARTICLEDPREFIX: {
		vi: "/vaccine-covid-19-cua-chung-toi",
		en: "/our-covid-19-vaccine",
	},
	LATESTNEWPREFIX: {
		vi: "/tin-moi-nhat",
		en: "/latest-news",
	},
	PRESSRELEASEPREFIX: {
		vi: "/thong-cao-bao-chi",
		en: "/press-releases",
	},
	ARTICLENEWSPREFIX: {
		vi: "/tin-dac-biet",
		en: "/article-news",
	},
	403: {
		vi: "/403",
		en: "/403",
	},
	500: {
		vi: "/500",
		en: "/500",
	},
	503: {
		vi: "/503",
		en: "/503",
	},
} as const;
