import React from "react";

import { Icon } from "components/atoms/Icon";
import { Image } from "components/atoms/Image";
import { Text } from "components/atoms/Text";
import { Link } from "components/utils/Link";
import { mapModifiers } from "helpers/component";

type TitleSize = "large" | "largest";
type TitleFontWeight = "medium" | "bold";

export interface Props {
	title: string;
	description?: string;
	imgSrc: string;
	href?: string;
	target?: string;
	titleSize?: TitleSize;
	titleFontWeight?: TitleFontWeight;
	lineColor?: string;
	subTitle?: string;
	subHref?: string;
	subTarget?: string;
}

export const OurValuesCard: React.FC<Props> = ({
	title,
	description,
	imgSrc,
	href,
	target,
	titleSize,
	titleFontWeight,
	lineColor,
	subTitle,
	subHref,
	subTarget,
}) => (
	<div
		className={mapModifiers("m-ourvaluescard", titleSize)}
		style={{ borderBottom: lineColor ? `5px solid ${lineColor}` : 0 }}
	>
		<Image src={imgSrc} aspectRatio="1x1" alt={title} />
		<div className="m-ourvaluescard_content">
			{href ? (
				<Link to={href} target={target} className="m-ourvaluescard_title">
					<Text fontWeight={titleFontWeight}>{title}</Text>
					<Icon iconName="arsenic-right-circle-arrow" />
				</Link>
			) : (
				<div className="m-ourvaluescard_title">
					<Text fontWeight={titleFontWeight}>{title}</Text>
				</div>
			)}
			<div className="m-ourvaluescard_description">
				{description && <Text innerHtml={description} />}
				{subTitle && subHref && (
					<Link
						to={subHref}
						target={subTarget}
						className="m-ourvaluescard_sublink"
					>
						<Text fontStyle="italic" fontWeight="bold">
							{subTitle}
						</Text>
						<Icon iconName="french-plum-right-circle-arrow" />
					</Link>
				)}
			</div>
		</div>
	</div>
);

OurValuesCard.defaultProps = {
	titleFontWeight: "bold",
};
