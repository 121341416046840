import React from "react";

import { Container } from "components/organisms/Grid";
import { mapModifiers } from "helpers/component";

type Modifiers = "no-horizontal-spacing";

export interface Props {
	modifiers?: Modifiers | Array<Modifiers>;
	backgroundColor?: string;
	fluid?: boolean;
	children?: React.ReactNode;
	useDiv?: boolean;
	id?: string;
}

export const Section: React.FC<Props> = ({
	useDiv,
	children,
	fluid,
	modifiers,
	id,
	backgroundColor,
}) => {
	return useDiv ? (
		<div
			className={mapModifiers(
				"o-section",
				modifiers,
				backgroundColor && "hasBackgroundColor"
			)}
			style={{ backgroundColor }}
			id={id}
		>
			<Container className="o-section_container" fluid={fluid}>
				{children}
			</Container>
		</div>
	) : (
		<section
			className={mapModifiers(
				"o-section",
				modifiers,
				backgroundColor && "hasBackgroundColor"
			)}
			style={{ backgroundColor }}
			id={id}
		>
			<Container className="o-section_container" fluid={fluid}>
				{children}
			</Container>
		</section>
	);
};
