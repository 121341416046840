import http from "services/http";

import { SystemsGeneral, Locales, SystemsRedirect } from "./types";

export const apiSystemsLocales = () =>
	http.get<BaseAPIResponse<Locales>>("/systems/locales");

export const apiSystemsGeneral = () =>
	http.get<BaseAPIResponse<SystemsGeneral>>("/systems/general");

export const apiSystems301Redirect = (path: string) =>
	http.get<BaseAPIResponse<SystemsRedirect>>("/systems/redirects", {
		params: { path },
		shouldNOTUseLocale: true,
	});
