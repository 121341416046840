import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiPagesStaticSlug } from "services/Page";

export const pagesStaticSlugAction = createAsyncThunk(
	"page/pagesStaticSlugAction",
	async () => {
		const res = await apiPagesStaticSlug();
		const pagesStaticSlugCustom = res.data.data.reduce(
			(accumulator, currentValue) => ({
				...accumulator,
				[currentValue.templateCode]: currentValue.slug,
			}),
			{}
		);
		return { staticSlug: pagesStaticSlugCustom };
	}
);
