import React from "react";

import { Button } from "components/atoms/Button";
import { Image } from "components/atoms/Image";
import { Text } from "components/atoms/Text";
import { mapModifiers } from "helpers/component";

type Type = "award" | "activity" | "latestnews" | "companynews" | "vaccines";

export interface Props {
	type: Type;
	imgSrc: string;
	imgAlt?: string;
	title?: string;
	subTitle?: string;
	tagName?: React.ReactNode;
	date?: string;
	description?: string;
	href?: string;
	buttonTitle?: string;
	target?: string;
}

export const InformationCard: React.FC<Props> = ({
	imgSrc,
	imgAlt,
	title,
	subTitle,
	description,
	tagName,
	date,
	type,
	href,
	buttonTitle,
	target,
}) => {
	return (
		<div className={mapModifiers("m-informationcard", type)}>
			<Image alt={imgAlt || ""} aspectRatio={type} src={imgSrc} />
			<div className="m-informationcard_content">
				{tagName && (
					<div className="m-informationcard_tagname">
						<Text typeSize="small">{tagName}</Text>
					</div>
				)}
				{date && (
					<div className="m-informationcard_date">
						<Text typeSize="small" fontStyle="italic">
							{date}
						</Text>
					</div>
				)}
				{title && (
					<div className="m-informationcard_heading">
						<Text fontWeight="medium" typeSize="large">
							{title}
						</Text>
					</div>
				)}
				{subTitle && (
					<div className="m-informationcard_subtitle">
						<Text typeSize="small">{subTitle}</Text>
					</div>
				)}
				{description && (
					<div className="m-informationcard_description">
						<Text
							typeSize="small"
							fontStyle={type === "vaccines" ? "italic" : undefined}
							innerHtml={description}
						/>
					</div>
				)}
				{buttonTitle && (
					<Button
						iconName="arsenic-right-circle-arrow"
						href={href}
						buttonType="outline"
						target={target}
					>
						{buttonTitle}
					</Button>
				)}
			</div>
		</div>
	);
};
