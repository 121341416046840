import React from "react";

import { Button } from "components/atoms/Button";
import { Heading } from "components/atoms/Heading";
import { Image } from "components/atoms/Image";
import { Link } from "components/utils/Link";
import { mapModifiers } from "helpers/component";

type Type = "primary" | "secondary";

export interface Props {
	type: Type;
	imgSrc: string;
	caption: React.ReactNode;
	href: string;
	target?: string;
	lineColor: string;
	hasLearnMore?: boolean;
	learnMoreTitle?: string;
	onLearnMore?: () => void;
}

export const CaptionBlock: React.FC<Props> = ({
	imgSrc,
	caption,
	href,
	target,
	hasLearnMore,
	learnMoreTitle,
	type,
	lineColor,
	onLearnMore,
}) => {
	return (
		<div
			className={mapModifiers("m-captionblock", type)}
			style={{
				borderBottom: `5px solid ${lineColor}`,
				borderTop: type === "primary" ? `5px solid ${lineColor}` : 0,
			}}
		>
			<Link to={href} target={target}>
				<Image
					alt=""
					aspectRatio={
						type === "primary" ? "captionblockprimary" : "captionblocksecondary"
					}
					src={imgSrc}
				/>
			</Link>
			<div className="m-captionblock_content">
				<Heading
					useParagraph
					type={type === "primary" ? "h2" : "h3"}
					fontWeight="medium"
				>
					<Link to={href} target={target}>
						{caption}
					</Link>
				</Heading>
				{hasLearnMore && (
					<Button
						buttonType="outline"
						iconName="french-plum-right-circle-arrow"
						onClick={onLearnMore}
					>
						{learnMoreTitle}
					</Button>
				)}
			</div>
		</div>
	);
};
