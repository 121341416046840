import React from "react";

import { Icon } from "components/atoms/Icon";
import { mapModifiers } from "helpers/component";

type Modifier = "transparent" | "oversize" | "center" | "circled" | "fullwidth";

type Ratio =
	| "16x9"
	| "1x1"
	| "2x1"
	| "3x2"
	| "3x4"
	| "4x3"
	| "award"
	| "activity"
	| "report"
	| "latestnews"
	| "environmentalprotection"
	| "response"
	| "captionblockprimary"
	| "captionblocksecondary"
	| "workplaceslider"
	| "companynews"
	| "timeline"
	| "vaccines"
	| "ourteam"
	| "sustainability"
	| "workplaceslidercomment"
	| "workplaceslidermodal"
	| "hearfromourteam"
	| "ourcovid19vaccine"
	| "healthylungprogramme"
	| "younghealthprogrammesingle"
	| "younghealthprogramme"
	| "careme"
	| "ourjourneyinvietnam"
	| "sustainabilitycommitment"
	| "articlenews";

export interface Props {
	modifiers?: Modifier | Modifier[];
	src: string;
	alt: string;
	lazy?: boolean;
	aspectRatio?: Ratio;
	useVideoThumb?: boolean;
	onPlay?: () => void;
}

export const Image: React.FC<Props> = ({
	src,
	alt,
	aspectRatio,
	modifiers,
	lazy,
	useVideoThumb,
	onPlay,
}) => (
	<div
		className={mapModifiers(
			"a-image",
			aspectRatio,
			aspectRatio ? "fixed" : undefined,
			modifiers,
			useVideoThumb && "videothumb"
		)}
	>
		<img
			className="a-image_img"
			loading={lazy ? "lazy" : undefined}
			src={src}
			alt={alt}
		/>
		{useVideoThumb && <Icon iconName="white-play" clickable onClick={onPlay} />}
	</div>
);

Image.defaultProps = {
	lazy: false,
};
