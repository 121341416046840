import React, { CSSProperties } from "react";

import { mapModifiers } from "helpers/component";

type FontWeight = "medium" | "semibold" | "bold";
type FontStyle = "italic";
type Size = "large" | "small" | "xsmall";
type TextAlign = "center" | "left" | "right" | "justify";

export interface Props {
	fontWeight?: FontWeight;
	fontStyle?: FontStyle;
	typeSize?: Size;
	textAlign?: TextAlign;
	children?: React.ReactNode;
	style?: CSSProperties;
	innerHtml?: string;
}

export const Text: React.FC<Props> = ({
	fontWeight,
	fontStyle,
	typeSize,
	textAlign,
	children,
	style,
	innerHtml,
}) => (
	<p
		style={style}
		className={mapModifiers(
			"a-text",
			fontWeight && `font-weight-${fontWeight}`,
			fontStyle && `font-style-${fontStyle}`,
			typeSize && `size-${typeSize}`,
			textAlign
		)}
		dangerouslySetInnerHTML={innerHtml ? { __html: innerHtml } : undefined}
	>
		{children}
	</p>
);
