import { useEffect } from "react";

import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "stores";

export const GoogleAnalytics = () => {
	const location = useLocation();
	const { general } = useAppSelector((state) => state.system);

	useEffect(() => {
		if (!general?.googleAnalytics) return;
		ReactGA.initialize(general.googleAnalytics, { debug: false });
		ReactGA.pageview(location.pathname + location.search);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [general?.googleAnalytics]);

	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
	}, [location]);

	return null;
};
