import { getLinkThroughLocale } from "helpers/utils";
import { GroupMenu } from "stores/Menus";

export const convertToPattern = (link: string) => {
	const localeLink = getLinkThroughLocale(link);
	return new RegExp(`^${localeLink}/|^${localeLink}$`);
};

export const getChildHrefs = (menu: GroupMenu) => {
	const childHrefs: string[] = [];
	const getRecursiveChildHrefs = (handlerMenu: GroupMenu) => {
		handlerMenu.children?.forEach((item) => {
			childHrefs.push(item.href);
			if (item.children) getRecursiveChildHrefs(item);
		});
	};
	if (menu.children) getRecursiveChildHrefs(menu);
	return childHrefs;
};

export const checkActiveMenu = (menu: GroupMenu, pathname: string) =>
	convertToPattern(menu.href).test(pathname) ||
	getChildHrefs(menu).some((childHref) =>
		convertToPattern(childHref).test(pathname)
	);
