import { Col, Row } from "components/organisms/Grid";

export const HomePageSkeleton = () => (
	<div className="u-homepageskeleton">
		<div className="u-homepageskeleton_banner" />
		<div className="u-homepageskeleton_captionblocks">
			<Row>
				<Col lg="8">
					<div className="u-homepageskeleton_captionblockprimary">
						<div className="u-homepageskeleton_captionblockcontent">
							<span className="u-homepageskeleton_captionblockcaption" />
							<span className="u-homepageskeleton_captionblockcaption" />
							<span className="u-homepageskeleton_captionblockcaption" />
							<span className="u-homepageskeleton_captionblocklearnmore" />
						</div>
					</div>
				</Col>
				<Col
					lg="4"
					className="d-flex flex-column justify-content-between u-mt-20 u-mt-lg-0"
				>
					<div className="u-mt-20 u-mt-lg-0">
						<div className="u-homepageskeleton_captionblocksecondary">
							<div className="u-homepageskeleton_captionblockcontent">
								<span className="u-homepageskeleton_captionblockcaption" />
								<span className="u-homepageskeleton_captionblockcaption" />
								<span className="u-homepageskeleton_captionblockcaption" />
							</div>
						</div>
					</div>
					<div className="u-mt-20 u-mt-lg-0">
						<div className="u-homepageskeleton_captionblocksecondary">
							<div className="u-homepageskeleton_captionblockcontent">
								<span className="u-homepageskeleton_captionblockcaption" />
								<span className="u-homepageskeleton_captionblockcaption" />
								<span className="u-homepageskeleton_captionblockcaption" />
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	</div>
);
