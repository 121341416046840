import { useState, useRef, useCallback } from "react";

import ReactPlayer from "react-player";

import iconPlay from "assets/images/system/video-play-icon.svg";
import iconPlayWhite from "assets/images/system/white-video-play-icon.svg";
import { Image } from "components/atoms/Image";
import { mapModifiers } from "helpers/component";

export interface Props {
	source: string;
	thumbnail: string;
	useWhitePlayIcon?: boolean;
	hasOverlay?: boolean;
	defaultAutoPlay?: boolean;
}

export const Video: React.FC<Props> = ({
	source,
	thumbnail,
	useWhitePlayIcon,
	hasOverlay,
	defaultAutoPlay,
}) => {
	const [isPlaying, setPlaying] = useState<boolean>(false);
	const videoRef = useRef<HTMLDivElement | null>(null);
	const soundRef = useRef<number>(0);

	const onControlVideoSound = useCallback((videoElm: HTMLVideoElement) => {
		const soundIntervalInstance = setInterval(() => {
			if (soundRef.current >= 1) {
				clearInterval(soundIntervalInstance);
				return;
			}
			const nextSound = Number((soundRef.current + 0.1).toFixed(1));
			videoElm.volume = nextSound;
			soundRef.current = nextSound;
		}, 500);
	}, []);

	const handleOnReady = useCallback(async () => {
		const videoElement = videoRef.current?.querySelector("video");
		if (!videoElement) return;

		// @see https://developer.chrome.com/blog/autoplay/
		try {
			// Autoplay with sound is allowed (ex: Click and open video modal in PC Chrome and PC Firefox)
			await videoElement.play();
		} catch (error) {
			// Autoplay with sound isn't allowed
			videoElement.muted = true;
			videoElement.play();
		}
	}, []);

	const onPlayFactory = useCallback(() => {
		const videoElement = videoRef.current?.querySelector("video");
		if (!videoElement) return;
		onControlVideoSound(videoElement);
	}, [onControlVideoSound]);

	return (
		<div
			className={mapModifiers(
				"a-video",
				useWhitePlayIcon && "whiteicon",
				hasOverlay && "overlay"
			)}
			ref={videoRef}
		>
			<ReactPlayer
				controls
				playing={isPlaying}
				playsinline
				loop
				playIcon={
					<div
						aria-hidden
						className="a-video_button"
						onClick={() => setPlaying(true)}
					>
						<Image
							src={useWhitePlayIcon ? iconPlayWhite : iconPlay}
							alt="video"
							aspectRatio="1x1"
							modifiers="transparent"
						/>
					</div>
				}
				light={defaultAutoPlay ? undefined : thumbnail}
				className="a-video_reactplayer"
				url={source}
				width="100%"
				height="100%"
				config={{
					file: {
						attributes: { controlsList: "nodownload" },
					},
				}}
				onReady={defaultAutoPlay ? handleOnReady : undefined}
				onPlay={onPlayFactory}
			/>
		</div>
	);
};
