import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "stores";
import systemSlice from "stores/System/reducer";
import i18n from "translation";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withLocaleProtection = (WrappedComponent: React.FC<any>) => (
	props: unknown
) => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { general, isOpenningLocaleModal } = useAppSelector(
		(state) => state.system
	);

	useEffect(() => {
		if (isOpenningLocaleModal) return;
		if (
			i18n.language &&
			general?.locales &&
			!general.locales[i18n.language]?.active
		) {
			dispatch(
				systemSlice.actions.toggleLocaleModal({ shouldRedirect302: true })
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [general?.locales, isOpenningLocaleModal, location.pathname]);

	return (
		<WrappedComponent
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
};
