import React, { CSSProperties, useMemo } from "react";

import { mapModifiers } from "helpers/component";

type HeadingType = "h1" | "h2" | "h3";
type FontWeight = "medium" | "semibold" | "bold";
type FontStyle = "italic";

export interface Props {
	type?: HeadingType;
	fontWeight?: FontWeight;
	fontStyle?: FontStyle;
	useParagraph?: boolean;
	centered?: boolean;
	innerHtmlChildren?: string;
	style?: CSSProperties;
}

export const Heading: React.FC<Props> = ({
	type,
	fontWeight,
	fontStyle,
	useParagraph,
	centered,
	children,
	innerHtmlChildren,
	style,
}) => {
	const renderHeadingText = useMemo(() => {
		switch (type) {
			case "h1":
				return (
					<h1
						className="a-heading_text"
						dangerouslySetInnerHTML={
							innerHtmlChildren ? { __html: innerHtmlChildren } : undefined
						}
					>
						{children}
					</h1>
				);
			case "h2":
				return (
					<h2
						className="a-heading_text"
						dangerouslySetInnerHTML={
							innerHtmlChildren ? { __html: innerHtmlChildren } : undefined
						}
					>
						{children}
					</h2>
				);
			case "h3":
				return (
					<h3
						className="a-heading_text"
						dangerouslySetInnerHTML={
							innerHtmlChildren ? { __html: innerHtmlChildren } : undefined
						}
					>
						{children}
					</h3>
				);
			default:
				return null;
		}
	}, [children, innerHtmlChildren, type]);

	return (
		<div
			className={mapModifiers(
				"a-heading",
				`type-${type}`,
				centered && "centered",
				fontWeight && `font-weight-${fontWeight}`,
				fontStyle && `font-style-${fontStyle}`
			)}
			style={style}
		>
			{useParagraph ? (
				<p
					className="a-heading_text"
					dangerouslySetInnerHTML={
						innerHtmlChildren ? { __html: innerHtmlChildren } : undefined
					}
				>
					{children}
				</p>
			) : (
				renderHeadingText
			)}
		</div>
	);
};

Heading.defaultProps = {
	type: "h2",
};
