import { lazy, Suspense, useEffect } from "react";

import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { Button } from "components/atoms/Button";
import { Heading } from "components/atoms/Heading";
import { Text } from "components/atoms/Text";
import { Modal } from "components/organisms/Modal";
import { Section } from "components/organisms/Section";
import { Layout } from "components/templates/Layout";
import PATHS from "constants/paths";
import ProtectedRoute from "helpers/protectedRoute";
import { useAppDispatch, useAppSelector } from "stores";
import pageSlice from "stores/Page/reducer";
import systemSlice from "stores/System/reducer";
import i18n from "translation";

const NotFound = lazy(() => import("./NotFound/redirect"));
const PressReleasesDetailPage = lazy(() => import("./PressReleases/Detail"));
const LatestNewsDetailPage = lazy(() => import("./LatestNews/Detail"));
const FourZeroThreePage = lazy(() => import("./403"));
const FiveZeroThreePage = lazy(() => import("./503"));
const FiveZeroZeroPage = lazy(() => import("./500"));
const ArticleNewsDetailPage = lazy(() => import("./ArticleNews/Detail"));

function Routes() {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const {
		isInitializedI18n,
		isOpenningLocaleModal,
		shouldRedirect302,
		general,
	} = useAppSelector((store) => store.system);
	const { locales } = general || {};
	const systemTranslationData = isInitializedI18n
		? i18n.getResource(i18n.language, "local", "system")
		: null;

	const createPathsViaPrefix = (
		prefix: typeof PATHS[keyof typeof PATHS],
		withSlug?: true
	) => {
		const slugPath = withSlug ? "/:slug" : "";
		return [`/en${prefix.en}${slugPath}`, `${prefix.vi}${slugPath}`];
	};

	const handleCloseLocaleModal = async () => {
		if (shouldRedirect302 && locales) {
			const langKeys = Object.keys(locales);
			const defaultLang = langKeys.find((k) => locales[k].default);
			const redirectedLang =
				defaultLang && locales[defaultLang].active
					? defaultLang
					: langKeys.find((k) => locales[k].active);
			if (!redirectedLang) return;
			await i18n.changeLanguage(redirectedLang);
			dispatch(pageSlice.actions.updateHomePage());
			history.replace(`/${redirectedLang === "vi" ? "" : redirectedLang}`, {
				redirect302: true,
			});
		}
		dispatch(systemSlice.actions.toggleLocaleModal());
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	if (!isInitializedI18n) return null;

	return (
		<Layout>
			<Suspense fallback={null}>
				<Switch>
					<Route
						path={createPathsViaPrefix(PATHS.LATESTNEWPREFIX, true)}
						exact
						component={LatestNewsDetailPage}
					/>
					<Route
						path={createPathsViaPrefix(PATHS.PRESSRELEASEPREFIX, true)}
						exact
						component={PressReleasesDetailPage}
					/>
					<Route
						path={createPathsViaPrefix(PATHS.ARTICLENEWSPREFIX, true)}
						exact
						component={ArticleNewsDetailPage}
					/>
					<Route
						path={createPathsViaPrefix(PATHS.ARTICLEDPREFIX, true)}
						exact
						component={LatestNewsDetailPage}
					/>
					<Route
						path={createPathsViaPrefix(PATHS[403])}
						exact
						component={FourZeroThreePage}
					/>
					<Route
						path={createPathsViaPrefix(PATHS[503])}
						exact
						component={FiveZeroThreePage}
					/>
					<Route
						path={createPathsViaPrefix(PATHS[500])}
						exact
						component={FiveZeroZeroPage}
					/>
					<Route
						path={["/en/:slugPage?", "/:slugPage?"]}
						exact
						component={ProtectedRoute}
					/>
					<Route path="*" exact component={NotFound} />
				</Switch>
			</Suspense>
			<Modal
				isOpen={isOpenningLocaleModal}
				onCloseModal={handleCloseLocaleModal}
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				closable
				style={{
					content: {
						maxWidth: 700,
					},
				}}
			>
				<Section>
					<Section useDiv>
						<Heading>{systemTranslationData?.localeInactiveTitle}</Heading>
						<div className="u-mt-20">
							<Text>{systemTranslationData?.localeInactiveDescription}</Text>
						</div>
					</Section>
					<Section useDiv>
						<Button
							iconName="arsenic-right-arrow"
							onClick={handleCloseLocaleModal}
						>
							{systemTranslationData?.localeInactivClose}
						</Button>
					</Section>
				</Section>
			</Modal>
		</Layout>
	);
}
export default Routes;
