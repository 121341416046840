import { mapModifiers } from "helpers/component";

type Size = "large";
export type IconName =
	| "arsenic-search"
	| "arsenic-right-circle-arrow"
	| "arsenic-right-arrow"
	| "arsenic-round-right-arrow"
	| "lime-green-mail"
	| "lime-green-phone"
	| "black-times"
	| "black-hamburger"
	| "black-down-circle-arrow"
	| "grey-down-angle"
	| "grey-globe"
	| "grey-hamburger"
	| "grey-search"
	| "grey-times"
	| "pink-search"
	| "white-down-circle-arrow"
	| "arsenic-plus"
	| "arsenic-minus"
	| "white-times"
	| "white-play"
	| "left-french-plum-arrow"
	| "right-french-plum-arrow"
	| "french-plum-right-circle-arrow"
	| "white-right-circle-arrow"
	| "french-plum-times"
	| "arsenic-bottom-circle-arrow"
	| "quote-left"
	| "quote-right"
	| "arsenic-minus"
	| "arsenic-continue"
	| "white-right-arrow"
	| "video-play-icon";

export interface Props {
	iconName: IconName;
	size?: Size;
	clickable?: boolean;
	onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Icon: React.FC<Props> = ({
	iconName,
	size,
	clickable,
	onClick,
}) => {
	return (
		<span
			className={mapModifiers(
				"a-icon",
				iconName,
				size && `size-${size}`,
				clickable && "clickable"
			)}
			aria-hidden="true"
			onClick={clickable ? onClick : undefined}
		/>
	);
};
