import React from "react";

import { Helmet } from "react-helmet";

import { downloadStaticFile } from "services/File";
import { useAppSelector } from "stores";
import i18n from "translation";

/**
 * @see https://ogp.me/
 */

interface Props {
	seoData?: {
		title: string;
		description: string;
		keywords: string;
		thumbnail?: string;
	};
	ogData?: {
		ogTitle?: string;
		ogImage?: string;
		ogDescription?: string;
		ogType?: string;
	};
}

export const Seo: React.FC<Props> = ({ seoData, ogData, children }) => {
	const { general } = useAppSelector((state) => state.system);
	const { description, keywords, thumbnail, title } = seoData || {};
	const { seoTitle, seoDescription, seoKeyword, seoThumbnail, og } =
		general || {};
	const ogSystem = og && og[i18n.language];

	const ogTitle = ogData?.ogTitle || ogSystem?.title || title || seoTitle;

	const ogDescription =
		ogData?.ogDescription ||
		ogSystem?.description ||
		description ||
		seoDescription;

	const ogImage =
		ogData?.ogImage || ogSystem?.image || thumbnail || seoThumbnail;

	const ogType = ogData?.ogType || ogSystem?.type;

	return (
		<Helmet>
			<meta property="og:url" content={window.location.href} />

			{(keywords || seoKeyword) && (
				<meta name="keywords" key="keywords" content={keywords || seoKeyword} />
			)}

			{(description || seoDescription) && (
				<meta
					name="description"
					key="description"
					content={description || seoDescription}
				/>
			)}

			{ogTitle && <meta property="og:title" content={ogTitle} />}

			{ogDescription && (
				<meta property="og:description" content={ogDescription} />
			)}

			{ogType && <meta property="og:type" content={ogType} />}

			{ogImage && (
				<meta
					property="og:image"
					content={downloadStaticFile(ogImage as string)}
				/>
			)}

			{ogImage && (
				<meta
					name="twitter:image"
					content={downloadStaticFile(ogImage as string)}
				/>
			)}

			{(description || seoDescription) && (
				<meta
					name="twitter:description"
					content={description || seoDescription}
				/>
			)}

			{(title || seoTitle) && (
				<meta name="twitter:title" content={title || seoTitle} />
			)}

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content="@AstraZeneca" />

			{(title || seoTitle) && <title key="title">{title || seoTitle}</title>}

			{children}
		</Helmet>
	);
};
