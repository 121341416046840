import { CSSProperties, useState } from "react";

import { Video } from "components/atoms/Video";
import { Banner } from "components/organisms/Banner";
import { Modal } from "components/organisms/Modal";
import { Section } from "components/organisms/Section";
import { downloadStaticFile } from "services/File";

interface Props {
	isHomePage?: boolean;
	imgPCSrc: string;
	imgTABSrc?: string;
	imgSPSrc?: string;
	title?: string;
	description?: string;
	titleStyle?: CSSProperties;
	source?: string;
	hasVideo?: boolean;
	bannerNotUseBaseUrl?: boolean;
}

const IndexSection: React.FC<Props> = ({
	isHomePage,
	title,
	description,
	imgPCSrc,
	imgTABSrc,
	imgSPSrc,
	titleStyle,
	source,
	hasVideo,
	bannerNotUseBaseUrl,
}) => {
	const [isOpenModalVideo, setIsOpenModalVideo] = useState<boolean>(false);

	return (
		<Section useDiv fluid modifiers="no-horizontal-spacing">
			{imgPCSrc && (
				<Banner
					source={source}
					hasVideo={hasVideo}
					onClickOpenModal={() => setIsOpenModalVideo(true)}
					modifiers={isHomePage ? "homepage" : undefined}
					imgSPSrc={
						imgSPSrc
							? downloadStaticFile(imgSPSrc, bannerNotUseBaseUrl)
							: undefined
					}
					imgTABSrc={
						imgTABSrc
							? downloadStaticFile(imgTABSrc, bannerNotUseBaseUrl)
							: undefined
					}
					imgPCSrc={downloadStaticFile(imgPCSrc, bannerNotUseBaseUrl)}
					innerHtmlTitle={title}
					titleStyle={titleStyle}
					description={
						description ? (
							<p
								dangerouslySetInnerHTML={{
									__html: description,
								}}
							/>
						) : undefined
					}
				/>
			)}

			{source && (
				<Modal
					isOpen={isOpenModalVideo}
					onCloseModal={() => setIsOpenModalVideo((prev) => !prev)}
					shouldCloseOnEsc
					shouldCloseOnOverlayClick
					closable
					useToStoreVideo
					style={{
						content: {
							maxWidth: 912,
							backgroundColor: "transparent",
						},
					}}
					modifiers={["noSpacing", "closableWithHover"]}
				>
					<div style={{ width: "100%" }}>
						<Video
							source={downloadStaticFile(source)}
							thumbnail=""
							defaultAutoPlay
						/>
					</div>
				</Modal>
			)}
		</Section>
	);
};

export default IndexSection;
