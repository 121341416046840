import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withRedirect = (WrappedComponent: React.FC<any>) => (
	props: unknown
) => {
	const history = useHistory<{
		redirect301: boolean;
		redirect302: boolean;
	}>();
	const locationState = history?.location?.state || null;

	return (
		<>
			{locationState &&
				(locationState.redirect301 || locationState.redirect302) && (
					<Helmet>
						<meta
							name="render:status_code"
							content={locationState.redirect301 ? "301" : "302"}
						/>
						<meta
							name="render:header"
							content={`Location: ${window.location.href}`}
						/>
					</Helmet>
				)}
			<WrappedComponent
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
		</>
	);
};
