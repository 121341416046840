import React, { useEffect, useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";

import { Heading } from "components/atoms/Heading";
import { Image } from "components/atoms/Image";
import { Text } from "components/atoms/Text";
import { Container } from "components/organisms/Grid";
import { Link } from "components/utils/Link";
import { getLinkThroughLocale } from "helpers/utils";
import { downloadStaticFile } from "services/File";
import { IMenu } from "services/Menu/types";
import { CompanyInfomation, ISocialLinkData } from "services/System/types";

export interface Props {
	socialMedias: Array<ISocialLinkData>;
	menus: Array<IMenu>;
	quickLinks: Array<IMenu>;
	title: string;
	description: string;
	veevaId: string;
	dateOfPreparation: string;
	quickLinkTitle: string;
	logo: string;
	contact: CompanyInfomation;
}

export const Footer: React.FC<Props> = ({
	socialMedias,
	menus,
	quickLinks,
	title: aboutTitle,
	description,
	veevaId,
	dateOfPreparation,
	quickLinkTitle,
	logo,
	contact,
}) => {
	const { t } = useTranslation("translation");
	const quickLinksRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const quickLinksTarget = quickLinksRef.current;
		const titleElm = quickLinksTarget?.querySelector(".o-footer_title");

		if (!quickLinksTarget || !titleElm) return undefined;

		const handleDetectResize = (mqle: MediaQueryList | MediaQueryListEvent) => {
			if (!mqle.matches) return;
			quickLinksTarget.classList.remove("o-footer_quicklinks-closed");
		};

		const mql = window.matchMedia("(min-width: 768px)");

		if (typeof mql.addEventListener === "function") {
			mql.addEventListener("change", handleDetectResize);
		} else {
			mql.addListener(handleDetectResize);
		}

		const handleToggleMenus = () => {
			if (window.innerWidth > 768) return;
			quickLinksTarget.classList.toggle("o-footer_quicklinks-closed");
		};

		titleElm.addEventListener("click", handleToggleMenus);

		handleDetectResize(mql);

		return () => {
			titleElm.removeEventListener("click", handleToggleMenus);
			if (typeof mql.removeEventListener === "function") {
				mql.removeEventListener("change", handleDetectResize);
			} else {
				mql.removeListener(handleDetectResize);
			}
		};
	}, []);

	const renderSocialMedia = useMemo(
		() => (
			<div className="o-footer_socialmedia">
				{socialMedias.map(({ icon, text, url }, index) => (
					<Link
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						className="o-footer_socialicon"
						useNative
						target="_blank"
						to={getLinkThroughLocale(url)}
					>
						<Image src={downloadStaticFile(icon)} alt={text} />
					</Link>
				))}
			</div>
		),
		[socialMedias]
	);

	return (
		<footer className="o-footer">
			<Container>
				<div className="o-footer_top">
					<div className="o-footer_aboutsite">
						<div className="o-footer_title">
							<Heading type="h3" fontWeight="medium">
								{aboutTitle}
							</Heading>
						</div>
						<div className="o-footer_topdes">
							<Text typeSize="xsmall" innerHtml={description} />
						</div>
						<div className="o-footer_veevaid">
							<div dangerouslySetInnerHTML={{ __html: veevaId }} />
							<div dangerouslySetInnerHTML={{ __html: dateOfPreparation }} />
						</div>

						<div className="o-footer_socialpc">{renderSocialMedia}</div>
					</div>
					<div className="o-footer_quicklinks" ref={quickLinksRef}>
						<div className="o-footer_title">
							<Heading type="h3" fontWeight="medium">
								{quickLinkTitle}
							</Heading>
						</div>
						<ul className="o-footer_linkmenu">
							{quickLinks.map(
								({ id, link, reference, target, title, type }) => {
									const isCustomLink = type === "custom_link";
									return (
										<li className="o-footer_linkitem" key={id}>
											<Link
												useNative={isCustomLink}
												to={
													isCustomLink
														? link
														: getLinkThroughLocale(`/${reference?.slug}`)
												}
												target={target}
											>
												<Text typeSize="xsmall">{title}</Text>
											</Link>
										</li>
									);
								}
							)}
						</ul>
					</div>
					<div className="o-footer_contact">
						<div className="o-footer_title">
							<Heading type="h3" fontWeight="medium">
								{contact?.title}
							</Heading>
						</div>
						<Text typeSize="xsmall" innerHtml={contact?.address} />

						<div className="o-footer_contactlist">
							<Text typeSize="xsmall">
								<span>{t("footer.phoneNo")}:&nbsp;&nbsp;</span>
								<span>{contact?.phone}</span>
							</Text>
							<Text typeSize="xsmall">
								<span>{t("footer.companyRegistrationNo")}:&nbsp;&nbsp;</span>
								<span>{contact?.registrationNo}</span>
							</Text>
							<Text typeSize="xsmall">
								<span>{t("footer.dateOfIssuance")}:&nbsp;&nbsp;</span>
								<span>{contact?.dateOfIssuance}</span>
							</Text>
							<Text typeSize="xsmall">
								<span>{t("footer.placeOfIssuance")}:&nbsp;&nbsp;</span>
								<span>{contact?.placeOfIssuance}</span>
							</Text>
						</div>

						{contact?.image && (
							<Link
								to={contact?.linkImage || ""}
								className="o-footer_contactimage"
							>
								<Image
									src={downloadStaticFile(contact?.image || "")}
									alt="Ministry of Industry and Trade"
								/>
							</Link>
						)}
					</div>

					<div className="o-footer_socialmobile">{renderSocialMedia}</div>
				</div>

				<div className="o-footer_bottom">
					<div className="o-footer_logo">
						<Image src={logo} alt="logo" />
					</div>
					<ul className="o-footer_menu">
						{menus.map(({ id, link, reference, target, title, type }) => {
							const isCustomLink = type === "custom_link";
							return (
								<li className="o-footer_menuitem" key={id}>
									<Link
										useNative={isCustomLink}
										to={
											isCustomLink
												? link
												: getLinkThroughLocale(`/${reference?.slug}`)
										}
										target={target}
									>
										<Text typeSize="xsmall">{title}</Text>
									</Link>
								</li>
							);
						})}
					</ul>
					<div className="o-footer_copyright">
						<Text typeSize="xsmall">© AstraZeneca 2021</Text>
					</div>
				</div>
			</Container>
		</footer>
	);
};
