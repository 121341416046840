import React from "react";

import ReactModal from "react-modal";

import { Icon } from "components/atoms/Icon";
import { mapModifiers } from "helpers/component";

type Modifiers = "noSpacing" | "closableWithHover" | "workplaceslider";

export interface Props {
	modifiers?: Modifiers | Array<Modifiers>;
	isOpen: boolean;
	shouldCloseOnEsc?: boolean;
	shouldCloseOnOverlayClick?: boolean;
	style?: ReactModal.Styles;
	closable?: boolean;
	useToStoreVideo?: boolean;
	onCloseModal?: () => void;
}

export const Modal: React.FC<Props> = ({
	modifiers,
	isOpen,
	shouldCloseOnEsc,
	shouldCloseOnOverlayClick,
	children,
	style,
	closable,
	useToStoreVideo,
	onCloseModal,
}) => (
	<div className="o-modal">
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onCloseModal}
			ariaHideApp={false}
			overlayClassName={mapModifiers(
				"o-modal_overlay",
				useToStoreVideo && "useToStoreVideo"
			)}
			portalClassName="o-modal_portal"
			className={mapModifiers(
				"o-modal_content",
				modifiers,
				closable && "closable",
				useToStoreVideo && "useToStoreVideo"
			)}
			style={style || undefined}
			shouldCloseOnEsc={shouldCloseOnEsc}
			shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
		>
			{closable && (
				<div className="o-modal_heading">
					<div className="o-modal_close">
						<Icon iconName="white-times" onClick={onCloseModal} clickable />
					</div>
				</div>
			)}
			<div className="o-modal_body">{children}</div>
		</ReactModal>
	</div>
);

Modal.defaultProps = {
	closable: true,
	shouldCloseOnEsc: true,
	shouldCloseOnOverlayClick: true,
};
