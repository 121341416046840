import React from "react";

import { Footer } from "components/organisms/Footer";
import { Header } from "components/organisms/Header";
import { Main } from "components/organisms/Main";
import { downloadStaticFile } from "services/File";
import { useAppSelector } from "stores";

export const Layout: React.FC = ({ children }) => {
	const { general } = useAppSelector((state) => state.system);
	const { footer, quickLinks } = useAppSelector((state) => state.menus);

	const { companyInformation } = general || {};
	return (
		<div className="t-layout">
			<Header />
			<Main>{children}</Main>
			<Footer
				quickLinks={quickLinks || []}
				socialMedias={Object.values(general?.socialLink || {})}
				menus={footer || []}
				title={general?.footer?.col1Title || ""}
				description={general?.footer?.col1Description || ""}
				veevaId={general?.footer?.veevaId || ""}
				dateOfPreparation={general?.footer?.dateOfPreparation || ""}
				quickLinkTitle={general?.footer?.col2Title || ""}
				logo={downloadStaticFile(general?.footer?.logo || "")}
				contact={{
					title: companyInformation?.title || "",
					address: companyInformation?.address || "",
					phone: companyInformation?.phone || "",
					registrationNo: companyInformation?.registrationNo || "",
					dateOfIssuance: companyInformation?.dateOfIssuance || "",
					placeOfIssuance: companyInformation?.placeOfIssuance || "",
					image: companyInformation?.image || "",
					linkImage: companyInformation?.linkImage || "",
				}}
			/>
		</div>
	);
};
