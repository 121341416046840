import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import azLogo from "assets/images/system/az-logo-mobile.svg";
import azLogoWithText from "assets/images/system/az-logo-pc.svg";
import { Icon } from "components/atoms/Icon";
import { Image } from "components/atoms/Image";
import { Text } from "components/atoms/Text";
import { Container } from "components/organisms/Grid";
import { Link } from "components/utils/Link";
import { mapModifiers } from "helpers/component";
import { changeLanguage } from "helpers/navigation";
import { getLinkThroughLocale } from "helpers/utils";
import { downloadStaticFile } from "services/File";
import { useAppDispatch, useAppSelector } from "stores";
import { GroupMenu } from "stores/Menus/reducer";
import systemSlice from "stores/System/reducer";
import i18n from "translation";

import { checkActiveMenu } from "./utils";

const HeaderLocales = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const history = useHistory<any>();
	const dispatch = useAppDispatch();
	const { general } = useAppSelector((state) => state.system);
	const { locales } = general || {};

	const handleChangeLocale = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (!locales) return;
		const selectedValue = e.target.value;
		if (!locales[selectedValue].active) {
			dispatch(systemSlice.actions.toggleLocaleModal());
			return;
		}
		if (!history?.location?.state?.urlTranslations?.[selectedValue]) return;
		changeLanguage(selectedValue);
	};

	if (!locales) return null;

	return (
		<div className="o-header_language">
			<Image
				src={downloadStaticFile(locales[i18n.language]?.icon)}
				alt="Flag"
			/>
			<select
				className="o-header_languagedropdown"
				onChange={handleChangeLocale}
				value={i18n.language}
			>
				{Object.keys(locales).map((locale) => (
					<option key={locale} value={locale}>
						{locale.toUpperCase()}
					</option>
				))}
			</select>
		</div>
	);
};

const GlobalSites = () => (
	<>
		<Link
			className="o-header_website"
			to="https://www.astrazeneca.com/global/en/AstraZeneca-Websites.html"
			target="_blank"
		>
			AstraZeneca Websites
		</Link>
		<Link
			className="o-header_globalwebsite"
			to="https://www.astrazeneca.com/"
			target="_blank"
		>
			<Icon iconName="grey-globe" />
			Global site
		</Link>
	</>
);

export const Header: React.FC = () => {
	const { t } = useTranslation("translation");
	const history = useHistory();
	const { header } = useAppSelector((state) => state.menus);
	const { staticSlug } = useAppSelector((state) => state.page);
	const menuBarRef = useRef<HTMLDivElement | null>(null);
	const menuContentRef = useRef<HTMLDivElement | null>(null);
	const searchInputRef = useRef<HTMLInputElement | null>(null);
	const [isOpenMenuBar, setOpenMenubar] = useState<boolean>(false);
	const [isOpenSearchBar, setOpenSearchBar] = useState<boolean>(false);
	const [isPcStickyHeader, setPcStickyHeader] = useState<boolean>(false);
	const [subMenus, setSubMenus] = useState<GroupMenu[] | undefined>();

	const { location } = history;
	const searchParams = new URLSearchParams(history.location.search);
	const keyword = searchParams.get("keyword");

	const toggleMenuBar = useCallback(() => {
		if (window.innerWidth > 768) return;

		const menuBarTarget = menuBarRef.current;
		if (!menuBarTarget) return;
		if (isOpenSearchBar) toggleSearchBar();

		menuBarTarget.classList.toggle("o-header_bottommainwrapper-open");
		const isOpenRequest = menuBarTarget.classList.contains(
			"o-header_bottommainwrapper-open"
		);

		// Handle menu scrollbar after the transition end
		setTimeout(
			() => {
				menuBarTarget.classList.toggle("o-header_bottommainwrapper-showscroll");
			},
			isOpenRequest ? 300 : 0
		);

		setOpenMenubar(!isOpenMenuBar);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpenMenuBar, isOpenSearchBar]);

	const toggleSearchBar = useCallback(() => {
		if (isOpenMenuBar) toggleMenuBar();
		setOpenSearchBar((prevState) => !prevState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpenMenuBar]);

	const toggleDropdownMenu = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		if (window.innerWidth > 768) return;

		const triggerIcon = event.currentTarget;
		const mainDropdownElm = triggerIcon.nextElementSibling as HTMLElement;
		if (!mainDropdownElm) return;

		triggerIcon.classList.toggle("o-header_maintrigger-active");
		mainDropdownElm.classList.toggle("o-header_maindropdown-open");

		const isOpenRequest = mainDropdownElm.classList.contains(
			"o-header_maindropdown-open"
		);

		mainDropdownElm.style.minHeight = isOpenRequest
			? `${mainDropdownElm.scrollHeight}px`
			: "0px";
	};

	const getChildMenus = useCallback(
		(menu: GroupMenu) => {
			const getRecursiveChildMenus = (childMenus: GroupMenu[]) => (
				<div className="o-header_maindropdown">
					{childMenus.map((childMenu) => {
						const isActive = checkActiveMenu(childMenu, location.pathname);
						if (isActive) setSubMenus(childMenus);

						return (
							<React.Fragment key={childMenu.id}>
								<Link
									className={mapModifiers(
										"o-header_dropdownlink",
										isActive && "active"
									)}
									to={getLinkThroughLocale(childMenu.href)}
									target={childMenu.target}
									onClick={toggleMenuBar}
								>
									<Text typeSize="xsmall" fontStyle="italic">
										{childMenu.title}
									</Text>
								</Link>

								{childMenu.children && (
									<>
										<Icon
											clickable
											iconName="grey-down-angle"
											onClick={toggleDropdownMenu}
										/>
										{getRecursiveChildMenus(childMenu.children)}
									</>
								)}
							</React.Fragment>
						);
					})}
				</div>
			);

			return menu.children && getRecursiveChildMenus(menu.children);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[location.pathname, toggleMenuBar]
	);

	const handleSearch = useCallback(() => {
		const searchInputTarget = searchInputRef.current;
		if (
			!searchInputTarget ||
			!staticSlug ||
			keyword === searchInputTarget.value ||
			searchInputTarget.value === ""
		)
			return;
		history.push({
			pathname: getLinkThroughLocale(`/${staticSlug.SEARCH}`),
			search: `keyword=${searchInputTarget.value}`,
		});
		searchInputTarget.value = "";
		toggleSearchBar();
	}, [history, keyword, staticSlug, toggleSearchBar]);

	const handleKeyPressSearchInput = (
		event: React.KeyboardEvent<HTMLInputElement>
	) => {
		if (event.key !== "Enter") return;
		handleSearch();
		searchInputRef.current?.blur();
	};

	const renderMenus = useMemo(
		() =>
			header?.map((menu) => (
				<div
					key={menu.id}
					className={mapModifiers(
						"o-header_mainitem",
						!!menu.cssClass && menu.cssClass,
						checkActiveMenu(menu, location.pathname) && "active"
					)}
				>
					{menu.href ? (
						<div aria-hidden className="o-header_mainlink">
							<Link
								to={getLinkThroughLocale(menu.href)}
								target={menu.target}
								onClick={toggleMenuBar}
							>
								<Text typeSize="small">{menu.title}</Text>
							</Link>
							{menu.children && (
								<Icon
									clickable
									iconName="grey-down-angle"
									onClick={toggleDropdownMenu}
								/>
							)}
						</div>
					) : (
						<div
							aria-hidden
							className="o-header_mainlink"
							onClick={toggleDropdownMenu}
						>
							<Text typeSize="small">{menu.title}</Text>
							{menu.children && <Icon clickable iconName="grey-down-angle" />}
						</div>
					)}
					{menu.children && getChildMenus(menu)}
				</div>
			)),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[header, getChildMenus, location.pathname, toggleMenuBar]
	);

	useEffect(() => {
		const onScrollEvent = () => {
			if (window.innerWidth < 768) return;

			// For preserving menu content height
			setOpenSearchBar(false);

			const menuContentTarget = menuContentRef.current;
			if (!menuContentTarget) return;

			const menuContentHeight = menuContentTarget.clientHeight;

			if (window.pageYOffset >= menuContentHeight && !isPcStickyHeader) {
				setPcStickyHeader(true);
			} else if (window.pageYOffset < menuContentHeight && isPcStickyHeader) {
				setPcStickyHeader(false);
			}
		};

		onScrollEvent();
		window.addEventListener("scroll", onScrollEvent);

		return () => {
			window.removeEventListener("scroll", onScrollEvent);
		};
	}, [isPcStickyHeader]);

	useEffect(() => {
		const hasActiveDropdownLink = menuBarRef.current?.querySelector(
			".o-header_dropdownlink-active"
		);
		if (hasActiveDropdownLink) return;

		setSubMenus(undefined);
	}, [location.pathname]);

	return (
		<header
			className={mapModifiers(
				"o-header",
				isPcStickyHeader && "pcsticky",
				subMenus && "opensub",
				isOpenSearchBar && "opensearch"
			)}
		>
			<div className="o-header_scrollmenuwrapper">
				<Container className="o-header_scrollmenu">
					<Link to={getLinkThroughLocale("/")} className="o-header_scrolllogo">
						<Image src={azLogo} alt="Logo" />
					</Link>
					<div className="o-header_scrollhamburger">
						<Icon iconName="grey-hamburger" />
					</div>
				</Container>
			</div>

			<div className="o-header_menucontent" ref={menuContentRef}>
				<div className="o-header_mainmenu">
					<div className="o-header_topmainwrapper">
						<Container>
							<div className="o-header_topmain">
								<Link to={getLinkThroughLocale("/")} className="o-header_logo">
									<Image src={azLogoWithText} alt="Logo" />
								</Link>
								<div className="o-header_toprightmain">
									<div className="o-header_languagegroup">
										<HeaderLocales />
										<GlobalSites />
									</div>

									<div className="o-header_searchwrapper">
										<div className="o-header_search">
											<Icon
												clickable
												iconName="pink-search"
												onClick={handleSearch}
											/>
											<div className="o-header_searchinputgroup">
												<input
													className="o-header_searchinput"
													placeholder={t("header.searchPlaceholder")}
													ref={searchInputRef}
													onKeyPress={handleKeyPressSearchInput}
												/>
												<div onClick={handleSearch} aria-hidden="true">
													<Text typeSize="xsmall">
														{t("header.searchButton")}
													</Text>
												</div>
											</div>
										</div>
										<Icon
											clickable
											iconName={isOpenSearchBar ? "grey-times" : "grey-search"}
											onClick={toggleSearchBar}
										/>
									</div>

									<div className="o-header_hamburger">
										<Icon
											clickable
											onClick={toggleMenuBar}
											iconName={
												isOpenMenuBar ? "black-times" : "black-hamburger"
											}
										/>
									</div>
								</div>
							</div>
						</Container>
					</div>

					<div className="o-header_bottommainwrapper" ref={menuBarRef}>
						<Container className="o-header_bottommain">{renderMenus}</Container>

						<div className="o-header_mobilelanguagegroup">
							<Container>
								<HeaderLocales />
								<GlobalSites />
							</Container>
						</div>
					</div>
				</div>

				{subMenus && (
					<div className="o-header_submenu">
						<Container>
							{subMenus.map((subMenu) => (
								<Link
									key={subMenu.id}
									className={mapModifiers(
										"o-header_sublink",
										checkActiveMenu(subMenu, location.pathname) && "active"
									)}
									to={getLinkThroughLocale(subMenu.href)}
									target={subMenu.target}
								>
									<Text typeSize="xsmall" fontStyle="italic">
										{subMenu.title}
									</Text>
								</Link>
							))}
						</Container>
					</div>
				)}
			</div>
		</header>
	);
};
