import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiGetMenus } from "services/Menu";
import { IMenu } from "services/Menu/types";

import { GroupMenu } from "./reducer";

export const footerMenusAction = createAsyncThunk(
	"menus/footerMenusAction",
	async () => {
		const res = await apiGetMenus("footer-menu");
		return { footer: res.data.data };
	}
);

export const footerQuickLinksAction = createAsyncThunk(
	"menus/footerQuickLinksAction",
	async () => {
		const res = await apiGetMenus("quick-links");
		return { quickLinks: res.data.data };
	}
);

const getMenuLink = ({ type, reference, link }: IMenu) =>
	// eslint-disable-next-line no-nested-ternary
	type === "custom_link"
		? link
		: // Homepage case
		reference?.slug === "/"
		? reference?.slug
		: `/${reference?.slug}`;

const groupMenus = (menus?: IMenu[]) => {
	if (!menus) return undefined;

	const createRecursiveMenus = (
		handlerMenus: IMenu[],
		parentId: number
	): GroupMenu[] => {
		const groupedMenus: GroupMenu[] = [];

		handlerMenus.forEach((menu) => {
			if (menu.parentId === parentId) {
				const children = createRecursiveMenus(handlerMenus, menu.id);
				groupedMenus.push(
					children.length > 0
						? { ...menu, href: getMenuLink(menu), children }
						: { ...menu, href: getMenuLink(menu) }
				);
			}
		});
		return groupedMenus;
	};

	if (menus.length > 0) {
		const firstLevelParentId = menus.find((menu) => menu.depth === 1)!.parentId;
		return createRecursiveMenus(menus, firstLevelParentId);
	}

	return [];
};

export const headerMenusAction = createAsyncThunk(
	"menus/headerMenusAction",
	async () => {
		const res = await apiGetMenus("menu");
		return { header: groupMenus(res.data.data) };
	}
);
