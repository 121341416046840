import React, { FunctionComponent, useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

import { HomePageSkeleton } from "components/utils/HomePageSkeleton";
import Templates from "constants/templates";
import { BasePageProps } from "helpers/component";
import { withLocaleProtection } from "hocs/withLocaleProtection";
import { withRedirect } from "hocs/withRedirect";
import { useAsyncAction } from "hooks/useAsyncAction";
import useDerivedStateFromProps from "hooks/useDerivedStateFromProps";
import { apiPagesSlug } from "services/Page";
import { IPageHome } from "services/Page/types";
import { useAppDispatch, useAppSelector } from "stores";
import pageSlice from "stores/Page/reducer";

import { updateLocalesHistoryState } from "./navigation";

const ProtectedRoute: React.FC<BasePageProps> = () => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { homePage } = useAppSelector((state) => state.page);
	const { slugPage } = useParams<ReturnType<typeof useParams>>();
	const isHomePage = typeof slugPage === "undefined";
	const isHomePageLoaded = isHomePage && homePage;

	const [pagesSlugExec, pagesSlugState] = useAsyncAction(apiPagesSlug, {
		onSuccess: (res) => {
			if (slugPage || homePage) return;
			dispatch(pageSlice.actions.updateHomePage(res.data.data as IPageHome));
		},
	});

	useEffect(() => {
		if (pagesSlugState.loading || !pagesSlugState.data?.data?.data) return;
		updateLocalesHistoryState(
			pagesSlugState.data.data.data.pageData.translations
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagesSlugState]);

	// NOTE: Use the location pathname instead of `slugPage`, to redirect from a pathname with an inactive language to a different path.
	useDerivedStateFromProps(() => {
		if (isHomePageLoaded) {
			updateLocalesHistoryState(homePage?.pageData?.translations || []);
			return;
		}
		pagesSlugExec(isHomePage ? "static/home-page" : (slugPage as string));
	}, location.pathname);

	if (pagesSlugState.loading) return isHomePage ? <HomePageSkeleton /> : null;

	const Component =
		Templates[
			isHomePageLoaded
				? "HOME"
				: ((pagesSlugState.data?.data?.data?.pageData?.templateCode ||
						"PAGE_404") as keyof typeof Templates)
		];

	if (!Component) return null;

	return React.createElement<{ pageState: unknown }>(
		(Component as unknown) as FunctionComponent,
		{
			pageState: isHomePageLoaded ? homePage : pagesSlugState.data?.data?.data,
		}
	);
};

export default withRedirect(withLocaleProtection(ProtectedRoute));
