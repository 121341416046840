import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IPageHome } from "services/Page/types";

import { pagesStaticSlugAction } from "./thunks";

export interface SystemReducerState {
	homePage?: IPageHome;
	staticSlug?: { [key: string]: string };
}

const initialState: SystemReducerState = {};

const pageSlice = createSlice({
	name: "page",
	initialState,
	reducers: {
		updateHomePage(state, action: PayloadAction<IPageHome | undefined>) {
			state.homePage = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(pagesStaticSlugAction.fulfilled, (state, action) => {
			state.staticSlug = action.payload.staticSlug;
		});
	},
});

export default pageSlice;
