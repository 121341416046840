import { AxiosResponse } from "axios";
import produce from "immer";

import { convertSnakeToCamelCase } from "helpers/utils";
import http from "services/http";

import { IPage, PagesStaticSlug } from "./types";

export const apiPagesSlug: (
	slug: string
) => Promise<AxiosResponse<BaseAPIResponse<IPage>>> = async (slug: string) => {
	try {
		const originalRes = await http.get<BaseAPIResponse<IPage>>(
			`/pages/${slug}`,
			{
				shouldCatchAPIsError: true,
				shouldCheckRedirectTo: true,
				isPreview: true,
			}
		);

		const customRes = produce(originalRes, (draft) => {
			const customBlocks =
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				draft.data.data.blocks?.map((block: any) => [
					convertSnakeToCamelCase(block.code),
					block.blocks,
				]) || [];
			draft.data.data.seoData.thumbnail = draft.data.data.pageData.thumbnail;
			draft.data.data.blocksData = Object.fromEntries(customBlocks);
			draft.data.data.blocks = null;
		});

		return customRes;
	} catch (err) {
		return err;
	}
};

export const apiPagesStaticSlug = () =>
	http.get<BaseAPIResponse<Array<PagesStaticSlug>>>("/pages/static/slug");
